import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'
import { BLOCKS } from '@contentful/rich-text-types'

import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
export const query = graphql`
  query($id: String!) {
    job: contentfulJob(contentful_id: { eq: $id }) {
      createdAt(formatString: "DD/MM/YYYY")
      id
      title
      entrepriseDescreption {
        json
      }
      jobDescreption {
        json
      }
    }
  }
`
const JobSingle = ({ data, intl }) => {
  const { title, createdAt, entrepriseDescreption, jobDescreption } = data.job
  const entrepriseDescreptionOptions = {
    renderNode: {
      [BLOCKS.UL_LIST]: node => {
        const values = node.content
        let txt = ''
        values.forEach(j => {
          txt = txt + '<li>' + j.content[0].content[0].value + '</li>'
        })
        return (
          <ul className="bullets">
            <div dangerouslySetInnerHTML={{ __html: txt }} />
          </ul>
        )
      }
    }
  }
  const jobDescreptionOptions = {
    renderNode: {
      [BLOCKS.UL_LIST]: node => {
        const values = node.content
        let txt = ''
        values.forEach(j => {
          txt = txt + '<li>' + j.content[0].content[0].value + '</li>'
        })
        return (
          <ul className="bullets">
            <div dangerouslySetInnerHTML={{ __html: txt }} />
          </ul>
        )
      }
    }
  }
  return (
    <Layout>
      <SEO title={title} />
      <PageTitle title={title} subtitle={title} />
      <section>
        <div className="container">
          <div className="row">
            <div>
              <div className="post-snippet mb64">
                <div className="post-title">
                  <span className="label">{createdAt}</span>
                  <h4 className="inline-block">{title}</h4>
                </div>
                <hr />
                <h6>
                  {intl.formatMessage({ id: 'jobSingle.companyDesc' })}
                </h6>
                {documentToReactComponents(
                  entrepriseDescreption.json,
                  entrepriseDescreptionOptions
                )}
                <hr />
                <h6>{intl.formatMessage({ id: 'jobSingle.jobDesc' })}</h6>
                {documentToReactComponents(
                  jobDescreption.json,
                  jobDescreptionOptions
                )}
              </div>
              <hr />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default injectIntl(JobSingle)
